import React from 'react';
import { useEffect } from 'react';
import { useLoader } from 'app/hooks/use-loader.hook';

interface Props {
  name?: string;
}

export const ShowLoader: React.FC<Props> = ({name = 'main'}) => {
  const loader = useLoader(name);
  useEffect(() => {
    loader.show();
    return () => {loader.hide();};
  }, []);

  return <React.Fragment/>;
};
